import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Employees = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [rolesList, setRolesList] = useState([]);
  const [gotRoles, setgotRoles] = useState(false);
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    getRoleListFromServer();
    let screenPermissions = RolePermissions.screenPermissions("Registered");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };
  const getRoleListFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.direction = "asc";
    url = `roles?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.roles && res.roles.length > 0) {
          let rolesList = [];
          for (let obj of res.roles) {
            if (obj.role) rolesList.push({ value: obj.role, label: obj.role });
          }
          setRolesList(rolesList);
          setgotRoles(true);
        }
      })
      .catch((err) => {
        setgotRoles(true);
        return err;
      });
  };
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: false,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Employee Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        textAlign: "left",
        displayinregisterForm: "true",
        disabled: false,
        globalSearchField: "true",
        show: true,
        field: "name",
        capitalizeTableText: true,
        fieldType: "Link",
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        addFormOrder: 1,
        editFormOrder: 1,
        fieldNameEditable: true,
        textCapitalize: true,
        defField: true,
        fieldName: "name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: true,

      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        id: "phone",
        width: 110,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: "true",
        show: true,
        disabled: false,
        field: "phone",
        header: "Phone",
        addFormOrder: 5,
        editFormOrder: 5,
        defField: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: true,


      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        displayinregisterForm: "true",
        disabled: false,
        show: true,
        globalSearchField: "true",
        field: "email",
        header: "Email",
        addFormOrder: 2,
        editFormOrder: 2,
        defField: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: true,

      },
      {
        show: true,
        textAlign: "center",
        width: 140,
        field: "role",
        mobile: true,
        header: "Role",
        filter: false,
        sortable: true,
        fieldType: "Role",
        options: config.employeeTableRolesOptions,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
        filter: true,

      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        field: "status",
        mobile: true,
        fieldType: "Badge",
        header: "Status",
        filter: false,
        options: config.tabfieldsStatusTypes,
        sortable: false,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
        filter: true,

      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "fname",
        type: "text",
        placeholder: "Name",
        label: "First Name",
        width: 110,
        id: "fname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        displayinregisterForm: "true",
        disabled: false,
        globalSearchField: "true",
        show: true,
        required: true,
        addFormOrder: 1,
        editFormOrder: 1,
        fieldNameEditable: true,
        defField: true,
        fieldName: "fname",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lname",
        type: "text",
        placeholder: "Name",
        label: "Last Name",
        width: 110,
        id: "lname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        displayinregisterForm: "true",
        disabled: false,
        globalSearchField: "true",
        show: true,
        required: true,
        addFormOrder: 1,
        editFormOrder: 1,
        fieldNameEditable: true,
        defField: true,
        fieldName: "lname",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        displayinregisterForm: "true",
        disabled: false,
        show: true,
        required: true,
        globalSearchField: "true",
        addFormOrder: 2,
        editFormOrder: 2,
        defField: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
           {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        show: true,
        disabled: false,
        required: true,
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        addFormOrder: 4,
        editFormOrder: 4,
        defField: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        id: "phone",
        width: 110,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        displayinregisterForm: "true",
        show: true,
        disabled: false,
        addFormOrder: 5,
        editFormOrder: 5,
        defField: true,
        mobile: true,
        required: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
     
      // {
      //   name: "password",
      //   type: "password",
      //   placeholder: "password",
      //   label: "password",
      //   id: "password",
      //   displayinaddForm: "true",
      //   displayineditForm: "false",
      //   displayinlist: "false",
      //   controllerName: null,
      //   show: true,
      //   displayInSettings: false,
      //   disabled: false,
      //   addFormOrder: 7,
      //   editFormOrder: 7,
      //   defField: true,
      //   mobile: true,
      //   isAddFormHidden: false,
      //   isEditFormHidden: true,
      // },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: false,
        show: true,
        addFormOrder: 10,
        editFormOrder: 10,
        defField: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: false,
        show: true,
        addFormOrder: 11,
        editFormOrder: 11,
        defField: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        textAlign: "Center",
        displayinregisterForm: "true",
        disabled: false,
        globalSearchField: "true",
        show: true,
        field: "name",
        addFormOrder: 1,
        editFormOrder: 1,
        fieldNameEditable: true,
        defField: true,
        fieldName: "name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: "true",
        disabled: false,
        show: true,
        globalSearchField: "true",
        field: "email",
        header: "Email",
        addFormOrder: 2,
        editFormOrder: 2,
        defField: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        id: "address",
        width: 180,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        show: true,
        disabled: false,
        globalSearchField: "true",
        field: "address",
        header: "Address",
        addFormOrder: 3,
        editFormOrder: 3,
        defField: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        header: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        textAlign: "Center",
        show: true,
        disabled: false,
        field: "role",
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        addFormOrder: 4,
        editFormOrder: 4,
        defField: true,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        id: "phone",
        width: 110,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: "true",
        show: true,
        disabled: false,
        field: "phone",
        header: "Phone",
        addFormOrder: 5,
        editFormOrder: 5,
        defField: true,
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        header: "ReportingTo",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "name",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        width: 110,
        textAlign: "Center",
        show: true,
        disabled: false,
        field: "reportingTo",
        addFormOrder: 6,
        editFormOrder: 6,
        defField: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "false",
        controllerName: null,
        textAlign: "Center",
        show: false,
        displayInSettings: false,
        disabled: false,
        field: "password",
        header: "password",
        addFormOrder: 7,
        editFormOrder: 7,
        defField: true,
        mobile: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: false,
        show: true,
        field: "created",
        addFormOrder: 10,
        editFormOrder: 10,
        defField: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: false,
        show: true,
        field: "updated",
        addFormOrder: 11,
        editFormOrder: 11,
        defField: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };


  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };
  const getChangedRoleOptions = () => {
    let fields = getFormFields();
    if (fields && fields.length > 0) {
      for (let obj of fields) {
        if (obj.name == "role") {
          obj.options = rolesList && rolesList.length > 0 ? rolesList : [];
        }
      }
    }
    return fields;
  };
  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }

    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.employees}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {

          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }

        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {gotRoles && (
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getChangedRoleOptions}

          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
   
          printRequried={false}
          actionsTypes={[
            {
              name: "Delete",
              options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
            },
            {
              name: "Active",
              options: [{ label: "Active", value: "Active", show: true, multiple: true }],
            },
            {
              name: "InActive",
              options: [
                { label: "InActive", value: "Inactive", show: true, multiple: true },
              ],
            },
            {
              name: "Invite",
              options: [
                {
                  label: "Invite",
                  value: "Invite",
                  show: true,
                  multiple: true,
                },
              ],
            },
            {
              name: "ResetPassword",
              options: [
                {
                  label: "Change Password",
                  value: "ResetPassword",
                  show: true,
                  multiple: false,
                },
              ],
            },
          ]}

          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          sampleFilePath={filePath.employees}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          globalSearch={
            "Employee Name / Email / School Name"
          }
          displayName="Administrators"
          type="Employees"
          routeTo={apiCalls.employees}
          sideFormLeftOrRight=""
          displayViewOfForm="modal"
          apiResponseKey={apiCalls.employees?.toLowerCase()}
          apiUrl={apiCalls.employees}
          selectedId={params.id}
        />
      )}
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="employees"
          apiUrl={apiCalls.employees}
        />
      ) : null}
    </span>
  );
};

export default Employees;
